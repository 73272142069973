import { ContentBlockType } from '@tytapp/content';
import { SocialShareBlock } from './social-share-block';

export * from './social-share-block';

export const SOCIAL_SHARE_BLOCK_TYPE: ContentBlockType<SocialShareBlock> = {
    id: 'social-share',
    label: 'Social Share',
    icon: 'social',
    categoryId: 'community',
    deferredEditorComponent: () => import('./social-share-block.edit').then(m => m.EditorComponent),
    deferredViewComponent: () => import('./social-share-block.view').then(m => m.ViewComponent)
};
